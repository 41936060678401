import React from "react";
import Particles from "./components/particles";
import { Skewbtn } from "./components/button";
import Content from "./components/content";
import "./styles/root.css";

function App() {
  const Connect = () => {
    alert("connect");
  };
  return (
    <div className="w-screen h-screen p-0 m-0 font-space text-white bg-black">
      <Particles />
      <div className="z-20 fixed w-full p-2 flex justify-between items-center">
        <div className="text-xl text-yg z-10">FUOC</div>
        <Skewbtn text="Connect" onClick={Connect} />
      </div>
      <Content />
    </div>
  );
}

export default App;
