import React from "react";

export function Skewbtn({ text, onClick }) {
  return (
    <button className="btn relative" onClick={onClick}>
      <div className="sec transform -skew-y-3 absolute bg-gray-800 w-full h-full"></div>
      <div className="con transform -skew-y-3 relative bg-yg px-2 py-1">
        {text}
      </div>
    </button>
  );
}
