import fullpage from "fullpage.js";
import "fullpage.js/dist/fullpage.css";
import React from "react";

const Articles = [
  {
    title: "FUOC",
    content:
      "FUOC is a project that aims to facilitate transactions in the scope of cryptocurrencies, FUOC will present various products that can help transaction behavior carried out by customers.",
  },
  {
    title: "FUOCT",
    content:
      "FUOC Token, is the main medium of exchange that we will use in the various facilities that we create, and as much as possible we will develop the process of using our tokens, so that our customers are not burdened with gas fees.",
  },
];

export default function Contents() {
  React.useEffect(() => {
    new fullpage("#fullpage", {
      navigation: true,
    });
  });
  return (
    <div id="fullpage" className="relative">
      {Articles.map((ctn, i) => {
        return (
          <div key={i} className="section flex justify-center items-center">
            <div className="relative p-10">
              <div className="relative bg-yg transform -skew-y-3 p-2">
                <h1 className="text-xl w-full text-center">{ctn.title}</h1>
                <p>{ctn.content}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
